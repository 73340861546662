import { ApolloClient, InMemoryCache, gql } from "@apollo/client/main.cjs";

export const COUNTRIES_QUERY = gql`
  query countries($locale: SupportedLocale!) {
    countries(locale: $locale) {
      name
      code
      continent
      phoneNumberPrefix
      autocompletionField
      provinceKey
      labels {
        address1
        address2
        city
        company
        country
        firstName
        lastName
        phone
        postalCode
        zone
      }
      formatting {
        edit
        show
      }
      zones {
        name
        code
      }
    }
  }
`;

export async function getCountries(locale = "EN_US") {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: "https://country-service.shopifycloud.com/graphql",
  });
  return client.query({
    query: COUNTRIES_QUERY,
    variables: {
      // TODO get store locale from somewhere
      locale,
    },
  });
}
