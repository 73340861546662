import { useApolloClient } from "@apollo/client/main.cjs";
import {
  Banner,
  Button,
  ContextualSaveBar,
  EmptyState,
  FormLayout,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Link,
  OptionList,
  Page,
  Spinner,
  Text,
} from "@shopify/polaris";
import { LocationMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { NO_OP_CALLBACK, SELLING_PLAN_GROUP_HIDDEN_TAG, adminRoutePrefix } from "@smartrr/shared/constants";
import { GetSellingPlanGroupsQuery, LocationFragmentFragment } from "@smartrr/shared/shopifyGraphQL/api";
import {
  FullDeliveryProfile,
  FullDeliveryProfileLocationGroup,
  mutationShopifyDeliveryProfileUpdate,
  paginatedQueryLocationsAvailableForDeliveryProfilesConnection,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { getLocale } from "@smartrr/shared/utils/getLocale";
import { loadFullDeliveryProfile } from "@smartrr/shared/utils/requestUtils";
import { flatten, isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";
import * as yup from "yup";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { updateOrgShippingSettings } from "@vendor-app/app/_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import { LocationGroup } from "./components/LocationGroup";
import { AddLocationModal } from "./components/modals/AddLocationModal";
import { ProductModal } from "./components/modals/ProductModal";
import { getSellingPlanGroups } from "../../AdminSellingPlanGroupsRoute/utils";
import { getCountries } from "../countries";
import { IShopifyCountryServiceCountry } from "../types";
import {
  addLocationGroupToFullDeliveryProfile,
  addLocationToProfileLocationGroup,
  fullDeliveryProfileToDeliveryProfileInput,
  hasAnyProductChanges,
  hasAnySellingPlanGroupsChanges,
} from "../utils/formatUtils";
import { createDeliveryProfile } from "../utils/requestUtils";
import { checkProfileConflicts } from "./utils/checkProfileConflicts";
import { ConflictWarningModal } from "./components/ConflictWarningModal";

type SellingPlanGroupNode = DeepExtractTypeSkipArrays<
  GetSellingPlanGroupsQuery,
  ["sellingPlanGroups", "edges", "node"]
>;

// @ts-ignore
const EMPTY_DELIVERY_PROFILE: FullDeliveryProfile = {
  sellingPlanGroups: [],
  profileLocationGroups: [],
  profileItems: [],
  unassignedLocations: [],
  name: "",
};

function getEmptyDeliveryProfile(locations: LocationFragmentFragment[]) {
  return {
    ...EMPTY_DELIVERY_PROFILE,
    unassignedLocations: locations,
  };
}

export function AdminDeliveryProfileRoute({ deliveryProfileId }: { deliveryProfileId: string }): JSX.Element {
  const apolloClient = useApolloClient();
  const dispatch = useSmartrrVendorDispatch();
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const { addToast } = useToast();
  const isCreatingNew = deliveryProfileId === "create";

  const [countries, setCountries] = useState<IShopifyCountryServiceCountry[]>([]);

  const [loadingDeliveryProfile, setLoadingDeliveryProfile] = useState(true);
  const [deliveryProfile, setDeliveryProfile] = useState<FullDeliveryProfile>();
  const [updatedDeliveryProfile, setUpdatedDeliveryProfile] = useState<FullDeliveryProfile>();

  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  const [, setLoadingLocations] = useState(false);
  const [locations, setLocations] = useState<LocationFragmentFragment[]>([]);

  const [, setLoadingSellingPlanGroups] = useState(false);
  const [sellingPlanGroups, setSellingPlanGroups] = useState<SellingPlanGroupNode[]>([]);
  const [selectedSellingPlanGroupIds, setSelectedSellingPlanGroupIds] = useState<string[]>([]);

  const [conflictingSellingPlanGroups, setConflictingSellingPlanGroups] = useState<SellingPlanGroupNode[]>([]);

  const [showAddLocationModalLocation, setShowAddLocationModalLocation] =
    useState<LocationFragmentFragment | null>(null);

  const [isSaving, setIsSaving] = useState(false);

  const { useField, useValues, validateForm, setFieldValue } = useTypedForm({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    onSubmit: NO_OP_CALLBACK,
  });

  const { Input: NameInput } = useField("name");
  const { name } = useValues();

  useEffect(() => {
    if (updatedDeliveryProfile) {
      setUpdatedDeliveryProfile({
        ...updatedDeliveryProfile,
        name,
      });
    }
  }, [name]);

  const hasChanges =
    !isEqual(deliveryProfile, updatedDeliveryProfile) ||
    hasAnySellingPlanGroupsChanges(deliveryProfile, selectedSellingPlanGroupIds) ||
    hasAnyProductChanges(deliveryProfile, selectedProductIds);

  const fetchDeliveryProfile = useCallback(async (id: number) => {
    setLoadingDeliveryProfile(true);
    try {
      const deliveryProfile: FullDeliveryProfile = await loadFullDeliveryProfile(id, apolloClient);
      setDeliveryProfile(deliveryProfile);
      setUpdatedDeliveryProfile(deliveryProfile);
      setSelectedSellingPlanGroupIds(deliveryProfile.sellingPlanGroups.map(group => group.id));
      setSelectedProductIds(deliveryProfile.profileItems.map(item => item.product.id));
      setFieldValue("name", deliveryProfile.name);
    } catch (error) {
      addToast(error.message);
      console.error(error);
    } finally {
      setLoadingDeliveryProfile(false);
    }
  }, []);

  const fetchLocations = useCallback(async () => {
    setLoadingLocations(true);
    const res = await paginatedQueryLocationsAvailableForDeliveryProfilesConnection(apolloClient);
    setLocations(res);
    setLoadingLocations(false);
    return res;
  }, []);

  const fetchSellingPlanGroups = useCallback(async () => {
    setLoadingSellingPlanGroups(true);
    const res = await getSellingPlanGroups(apolloClient);
    setSellingPlanGroups(res);
    setLoadingSellingPlanGroups(false);
  }, []);

  const fetchCountries = useCallback(async () => {
    const res = await getCountries(getLocale().toUpperCase().replace("-", "_"));
    setCountries(res.data.countries);
  }, []);

  const onSave = useCallback(
    async (conflictsHandled: boolean) => {
      if (!(deliveryProfile && updatedDeliveryProfile)) {
        return;
      }

      const res = await validateForm();
      if (Object.keys(res).length) {
        return;
      }

      if (!selectedSellingPlanGroupIds.length) {
        addToast("At least one subscription program is required.");
        return;
      }

      setIsSaving(true);

      if (!conflictsHandled) {
        const { conflictingProfiles, conflictingGroups } = await checkProfileConflicts(
          apolloClient,
          deliveryProfileId,
          selectedSellingPlanGroupIds,
          sellingPlanGroups
        );

        if (!!conflictingProfiles.length) {
          setConflictingSellingPlanGroups(conflictingGroups);
          setIsSaving(false);
          return;
        }
      }

      const deliveryProfileInput = fullDeliveryProfileToDeliveryProfileInput(
        deliveryProfile,
        updatedDeliveryProfile,
        selectedSellingPlanGroupIds,
        selectedProductIds,
        purchasables
      );

      try {
        if (isCreatingNew) {
          await createDeliveryProfile(deliveryProfileInput, apolloClient);
        } else if (deliveryProfileId) {
          await mutationShopifyDeliveryProfileUpdate(
            apolloClient,
            ensureShopifyGid("DeliveryProfile", deliveryProfileId),
            deliveryProfileInput
          );
          await fetchDeliveryProfile(Number(deliveryProfileId));
        } else {
          throw new Error("should not get here");
        }
      } catch (error) {
        let message;
        try {
          message = JSON.parse(error.message)
            .map((userError: any) => userError.message)
            .join(",");
        } catch {
          message = error.message;
        }
        addToast(`Error saving delivery profile: ${message}`);
      } finally {
        setIsSaving(false);
        dispatch(updateOrgShippingSettings());
      }
    },
    [
      deliveryProfile,
      updatedDeliveryProfile,
      selectedSellingPlanGroupIds,
      selectedProductIds,
      apolloClient,
      deliveryProfileId,
      sellingPlanGroups,
    ]
  );

  const onAddProfileLocationGroup = (location: LocationFragmentFragment) => {
    if (!updatedDeliveryProfile) {
      return;
    }
    setUpdatedDeliveryProfile(addLocationGroupToFullDeliveryProfile(updatedDeliveryProfile, location));
  };

  const onAddLocationToProfileLocationGroup = (
    profileLocationGroupToUpdate: FullDeliveryProfileLocationGroup,
    location: LocationFragmentFragment
  ) => {
    if (!updatedDeliveryProfile) {
      return;
    }
    setUpdatedDeliveryProfile(
      addLocationToProfileLocationGroup(updatedDeliveryProfile, profileLocationGroupToUpdate, location)
    );
  };

  useEffect(() => {
    fetchLocations().then(locations => {
      if (isCreatingNew) {
        setDeliveryProfile(getEmptyDeliveryProfile(locations));
        setUpdatedDeliveryProfile(getEmptyDeliveryProfile(locations));
        setLoadingDeliveryProfile(false);
      } else if (deliveryProfileId) {
        fetchDeliveryProfile(Number(deliveryProfileId));
      }
      fetchSellingPlanGroups();
      fetchCountries();
    });
  }, [deliveryProfileId]);

  const renderLoading = () => {
    return (
      <Layout.Section>
        <Box alignItems="center" justifyContent="center" p={2}>
          <Spinner />
        </Box>
      </Layout.Section>
    );
  };

  const renderForm = (deliveryProfile: FullDeliveryProfile) => {
    const profileLocationGroups: FullDeliveryProfileLocationGroup[] =
      deliveryProfile.profileLocationGroups as FullDeliveryProfileLocationGroup[];
    const assignedLocations = flatten(
      profileLocationGroups.map(
        profileLocationGroup => (profileLocationGroup as FullDeliveryProfileLocationGroup).locationGroup.locations
      )
    );
    const assignedLocationIds = assignedLocations.map(loc => loc.id);
    const unassignedLocationIds = deliveryProfile.unassignedLocations.map(loc => loc.id);

    const notShippingFromLocations = updatedDeliveryProfile?.unassignedLocations || [];
    const locationsThatMayBeAdded = locations.filter(
      loc => !assignedLocationIds.includes(loc.id) && !unassignedLocationIds.includes(loc.id)
    );

    return (
      <React.Fragment>
        <Layout.AnnotatedSection title="General settings">
          <LegacyCard>
            <LegacyCard.Section>
              <FormLayout>
                <NameInput usePolaris type="text" label="Name" />
              </FormLayout>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Subscription programs"
          description={
            <React.Fragment>
              Select the{" "}
              <Link onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/configure/plans`)}>
                subscription programs
              </Link>{" "}
              to associate with this delivery profile.
            </React.Fragment>
          }
        >
          <LegacyCard>
            <LegacyCard.Section>
              <OptionList
                options={sellingPlanGroups
                  .filter(group => !group.name.includes(SELLING_PLAN_GROUP_HIDDEN_TAG))
                  .map(group => ({
                    label: `${group.name} - ${group.options[0]}`,
                    value: group.id,
                  }))}
                selected={selectedSellingPlanGroupIds}
                onChange={setSelectedSellingPlanGroupIds}
                allowMultiple
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Location settings"
          description={
            <Box vertical gap={1}>
              <span>Configure which zones can be shipped to and any applicable delivery rates.</span>
              <span>
                This configuration flow is almost identical to the flow in Shopify&apos;s shipping admin UI. Refer
                to the{" "}
                <Link
                  external
                  url="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/setting-up-shipping-zones"
                >
                  documentation
                </Link>{" "}
                for that flow if you are not already familiar with configuring zones and rates.
              </span>
            </Box>
          }
        >
          {!updatedDeliveryProfile?.id && (
            <LegacyCard>
              <LegacyCard.Section>
                Please save the profile by clicking &quot;Save changes&quot; on the top right before adding zones.
              </LegacyCard.Section>
            </LegacyCard>
          )}
          {!!locationsThatMayBeAdded.length && (
            <Box mb={2}>
              <Banner status="warning">
                <div>
                  Smartrr has detected that some shipping zone groups may have &quot;from&quot; locations that
                  have been added but cannot be idenfitied. This can happen if the location is managed by a
                  third-party app such as ShipBob. We are working to identify the root issue and potential
                  workarounds.
                </div>
              </Banner>
            </Box>
          )}
          {!!updatedDeliveryProfile?.id &&
            profileLocationGroups.map((locationGroup, index) => (
              <LocationGroup
                key={index}
                index={index}
                profileLocationGroup={locationGroup}
                countries={countries}
                onDelete={() => {
                  if (!updatedDeliveryProfile) {
                    return;
                  }

                  // add locations back to unasigned pool
                  const oldProfileLocationGroup = profileLocationGroups[index];
                  const unassignedLocations = [...updatedDeliveryProfile.unassignedLocations];
                  unassignedLocations.push(...oldProfileLocationGroup.locationGroup.locations);

                  setUpdatedDeliveryProfile({
                    ...updatedDeliveryProfile,
                    unassignedLocations,
                    // @ts-ignore
                    profileLocationGroups: [
                      ...updatedDeliveryProfile.profileLocationGroups.slice(0, index),
                      ...updatedDeliveryProfile.profileLocationGroups.slice(index + 1),
                    ],
                  });
                }}
                onUpdate={updatedProfileLocationGroup => {
                  if (!updatedDeliveryProfile) {
                    return;
                  }

                  const oldProfileLocationGroup = profileLocationGroups[index];

                  const unassignedLocations = [...updatedDeliveryProfile.unassignedLocations];
                  if (
                    updatedProfileLocationGroup.locationGroup.locations.length <
                    oldProfileLocationGroup.locationGroup.locations.length
                  ) {
                    // location was removed, send it back to unassignedLocations
                    unassignedLocations.push(
                      ...oldProfileLocationGroup.locationGroup.locations.filter(
                        loc => !updatedProfileLocationGroup.locationGroup.locations.find(l => l.id === loc.id)
                      )
                    );
                  }

                  const newProfile = {
                    ...updatedDeliveryProfile,
                    unassignedLocations,
                    profileLocationGroups: [
                      ...profileLocationGroups.slice(0, index),
                      updatedProfileLocationGroup,
                      ...profileLocationGroups.slice(index + 1),
                    ],
                  };
                  setUpdatedDeliveryProfile(newProfile);
                }}
              />
            ))}
          {!!updatedDeliveryProfile?.id && !!notShippingFromLocations.length && (
            <LegacyCard>
              <LegacyCard.Section
                title={
                  <Text variant="headingMd" as="h2">
                    Not shipping from
                  </Text>
                }
              >
                <LegacyStack vertical>
                  {notShippingFromLocations.map(location => (
                    <Box justifyContent="space-between" key={location.id}>
                      <LegacyStack>
                        <Icon source={LocationMajor} />
                        <div>{location.name}</div>
                      </LegacyStack>
                      <Button plain onClick={() => setShowAddLocationModalLocation(location)}>
                        Add rates
                      </Button>
                    </Box>
                  ))}
                </LegacyStack>
              </LegacyCard.Section>
            </LegacyCard>
          )}
        </Layout.AnnotatedSection>

        {!!showAddLocationModalLocation && (
          <AddLocationModal
            open={!!showAddLocationModalLocation}
            location={showAddLocationModalLocation}
            profileLocationGroups={
              updatedDeliveryProfile
                ? (updatedDeliveryProfile.profileLocationGroups as FullDeliveryProfileLocationGroup[])
                : []
            }
            onClose={() => setShowAddLocationModalLocation(null)}
            onSave={selectedGroup => {
              if (selectedGroup === "new") {
                onAddProfileLocationGroup(showAddLocationModalLocation);
              } else {
                onAddLocationToProfileLocationGroup(selectedGroup, showAddLocationModalLocation);
              }
              setShowAddLocationModalLocation(null);
            }}
          />
        )}

        <ProductModal
          selectedProductIds={selectedProductIds}
          open={showProductModal}
          onSave={ids => {
            setSelectedProductIds(ids);
            setShowProductModal(false);
          }}
          onClose={() => setShowProductModal(false)}
        />
      </React.Fragment>
    );
  };

  if (!loadingDeliveryProfile && !deliveryProfile) {
    return (
      <EmptyState
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        action={{
          content: "Manage delivery profiles",
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/configure/delivery-profiles`),
        }}
      >
        <p>Delivery profile not found</p>
      </EmptyState>
    );
  }

  return (
    <Page
      title={
        deliveryProfileId === "create" ? "Create new delivery profile" : `Delivery profile ${deliveryProfileId}`
      }
      backAction={{
        onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/configure/delivery-profiles`),
        content: "Back to profiles",
      }}
    >
      {!!hasChanges && (
        <ContextualSaveBar
          discardAction={{
            content: "Discard changes",
            onAction() {
              setUpdatedDeliveryProfile(deliveryProfile);
              setSelectedSellingPlanGroupIds((deliveryProfile?.sellingPlanGroups || []).map(group => group.id));
              setSelectedProductIds((deliveryProfile?.profileItems || []).map(item => item.product.id));
              setFieldValue("name", deliveryProfile?.name);
            },
          }}
          saveAction={{
            loading: isSaving,
            content: "Save changes",
            onAction: () => onSave(false),
          }}
        />
      )}

      <Layout>
        {!!loadingDeliveryProfile && renderLoading()}
        {!loadingDeliveryProfile && !!updatedDeliveryProfile && renderForm(updatedDeliveryProfile)}
        {!!isSuperUser && (
          <pre style={{ fontFamily: "monospace" }}>{JSON.stringify(updatedDeliveryProfile, null, 2)}</pre>
        )}
      </Layout>

      <ConflictWarningModal
        onConfirm={() => onSave(true)}
        onClose={() => setConflictingSellingPlanGroups([])}
        conflictingSellingPlanGroups={conflictingSellingPlanGroups}
      />
    </Page>
  );
}
